import './Ratio.scss';
import { ReactNode } from 'react';

export const Ratio = ({ children, ratio }: { children: ReactNode, ratio: number }) => {
  return (
    <div className="ratio-component" style={{'paddingTop': ratio + '%'}}>
      <div className="content">
        {children}
      </div>
    </div>
  );
}
