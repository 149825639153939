import './GamesPage.scss';
import { Banner } from '../../components/banner/Banner';
import { GameCard } from '../../components/game-card/GameCard';
import { gameStore } from '../../../platform/stores';

export const GamesPage = () => {
  const games = gameStore.getGames();

  return (
    <div className="games-page">
      <Banner title="Games"/>
      <div className="container mt-5">
        <div className="row">
          {
            games.map((item) => (
              <div className="col-md-4 game-card-column mb-md-4 col-6" key={item.id}>
                <GameCard game={item}/>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}
