import './HomeBanner.scss';
import banner from '../../../../../assets/images/home-banner.png';

export const HomeBanner = () => {
  return (
    <div className="home-banner">
      <img src={banner} alt=""/>
      <h1 className="display-1">Offer More to Your Players</h1>
    </div>
  );
}
