import './ContactUs.scss';
import penIcon from '../../../assets/icons/pen.svg';
import userIcon from '../../../assets/icons/user.svg';
import emailIcon from '../../../assets/icons/email.svg';
import phoneIcon from '../../../assets/icons/phone.svg';
import subjectIcon from '../../../assets/icons/subject.svg';
import telegramIcon from '../../../assets/icons/telegram.svg';
import skypeIcon from '../../../assets/icons/skype.svg';
import linkedInIcon from '../../../assets/icons/linkedin.svg';
import { ChangeEvent, FormEvent, useState } from 'react';
import axios from 'axios';

interface FormData {
  name: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
}

export const ContactUs = () => {
  const initialFormData: FormData = {
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  };
  const [formErrors, setFormErrors] = useState<Record<keyof FormData, boolean>>({
    name: false,
    email: false,
    phone: false,
    subject: false,
    message: false,
  });
  const [formData, setFormData] = useState(initialFormData);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: false });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    // Validate form fields
    const newFormErrors = {} as Record<keyof FormData, boolean>;
    let isValid = true;

    for (const field in formData) {
      if (!formData[field as keyof FormData]) {
        newFormErrors[field as keyof FormData] = true;
        isValid = false;
      } else {
        newFormErrors[field as keyof FormData] = false;
      }
    }

    setFormErrors(newFormErrors);

    if (!isValid) {
      // Display an error message or handle invalid form data
      return;
    }

    try {
      setShowLoading(true);
      await axios.post('https://contacts-api.fostcraft.com/Contacts', formData);
      setShowErrorAlert(false);
      setShowSuccessAlert(true);
      setTimeout(() => {
        setShowSuccessAlert(false);
      }, 4000);
      setFormData(initialFormData);
    } catch (error) {
      console.error('Error submitting data:', error);
      setShowErrorAlert(true);
    }
    finally {
      setShowLoading(false);
    }
  };

  return (
    <div className="contact-us">
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-5">
            <h5 className="color-primary mb-3">CONTACT US</h5>
            <h2 className="mb-3 text-white">Have questions?<br/> Get in touch!</h2>
            <p className="text-white">
              You will find a lot of people, but they will happen at the same time as with some great work and pain. For at least
            </p>
            <div className="d-flex">
              <a href="skype:live:c84ac12fc91ad60b?chat" className="me-3" target="_blank">
                <img className="contact-us-icon" src={skypeIcon} alt="Skype Icon"/>
              </a>
              <a href="https://t.me/hayk_astriona" className="me-3" target="_blank">
                <img className="contact-us-icon" src={telegramIcon} alt="Telegram Icon"/>
              </a>
              <a href="https://www.linkedin.com/company/astriona" target="_blank">
                <img className="contact-us-icon" src={linkedInIcon} alt="LinkedIn Icon"/>
              </a>
            </div>
          </div>
          <div className="col-lg-6 offset-lg-1">
            <form onSubmit={handleSubmit}>
              <div className="row">
              <div className="col-md-6">
                  <div className="input">
                    <img src={userIcon} alt="User icon"/>
                    <input type="text" placeholder="Name" name="name" className={`${formErrors.name ? 'error' : ''}`} value={formData.name} onChange={handleInputChange}/>
                    <div className="line"></div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input">
                    <img src={emailIcon} alt="Email icon"/>
                    <input type="text" placeholder="Email" name="email" className={`${formErrors.email ? 'error' : ''}`} value={formData.email} onChange={handleInputChange}/>
                    <div className="line"></div>
                  </div>
                </div>
              </div>
              <div className="row mt-md-4">
                <div className="col-md-6">
                  <div className="input">
                    <img src={phoneIcon} alt="Phone icon"/>
                    <input type="text" placeholder="Phone" name="phone" className={`${formErrors.phone ? 'error' : ''}`} value={formData.phone} onChange={handleInputChange}/>
                    <div className="line"></div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input">
                    <img src={subjectIcon} alt="Info icon"/>
                    <input type="text" placeholder="Subject" name="subject" className={`${formErrors.subject ? 'error' : ''}`} value={formData.subject} onChange={handleInputChange}/>
                    <div className="line"></div>
                  </div>
                </div>
              </div>
              <div className="row mt-md-4">
                <div className="col-md-12">
                  <div className="input">
                    <img src={penIcon} alt="Message icon"/>
                    <textarea placeholder="Message" rows={3} name="message" className={`${formErrors.message ? 'error' : ''}`} value={formData.message} onChange={handleInputChange}></textarea>
                    <div className="line textarea"></div>
                  </div>
                </div>
              </div>
              <div className="mt-md-4">
                <button type="submit" className={`btn custom-btn btn-lg ${showLoading ? 'disabled' : ''}`}>GET IN TOUCH</button>
              </div>
            </form>
            {showSuccessAlert && (
              <div className="alert alert-success custom-success-alert mt-4" role="alert">
                Thank you for your message.
              </div>
            )}
            {showErrorAlert && (
              <div className="alert alert-danger custom-error-alert mt-4" role="alert">
                Something went wrong. Please try again later.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
