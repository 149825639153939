import './Header.scss';
import logoSmall from '../../../assets/images/logo-test.png';
import { Link, NavLink } from 'react-router-dom';
import close from '../../../assets/icons/close.svg';
import hamburger from '../../../assets/icons/hamburger.svg';
import { useState } from 'react';

export const Header = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <div className="header">
      <div className="header-hidden-element desktop-hidden"></div>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container align-items-center content">
            <div className="desktop-hidden">
              {
                showSidebar
                  ?
                  <img src={close} alt="Hamburger icon" onClick={() => setShowSidebar(false)}/>
                  :
                  <img src={hamburger} alt="Hamburger icon" onClick={() => setShowSidebar(true)}/>
              }
            </div>
            <Link className="navbar-brand" to="/">
              <img src={logoSmall} alt="Logo" height="90"/>
            </Link>
            <ul className="navbar-nav ml-auto mobile-hidden">
              <li className="nav-item">
                <NavLink to="/games" className={({ isActive }) => (`${isActive ? 'active' : 'inactive'} nav-link`)}>GAMES</NavLink>
              </li>
              <li className="nav-item ms-3">
                <NavLink to="/blog" className={({ isActive }) => (`${isActive ? 'active' : 'inactive'} nav-link`)}>BLOG</NavLink>
              </li>
            </ul>
            <div className="ml-auto d-flex align-items-center mobile-hidden">
              <Link to="/contact-us" className="btn custom-btn btn-lg">Let’s Talk</Link>
            </div>
            <div className="desktop-hidden"></div>
          </div>
        </nav>
      </header>
      <div className={`sidebar desktop-hidden ${showSidebar ? 'show' : ''}`}>
        <div className="background" onClick={() => setShowSidebar(false)}></div>
        <div className="content">
          <div className="mb-1 p-3 text-center">
            <Link to="/games" onClick={() => setShowSidebar(false)}>Games</Link>
          </div>
          <div className="mb-1 p-3 text-center">
            <Link to="/blog" onClick={() => setShowSidebar(false)}>Blog</Link>
          </div>
          <div className="mb-1 p-3 text-center">
            <Link to="/contact-us" onClick={() => setShowSidebar(false)}>Contact us</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
