import './MainLayout.scss';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../../components/header/Header';
import { Footer } from '../../components/footer/Footer';

export const MainLayout = () => {
  return (
    <div className="main-layout">
      <Header/>
      <Outlet/>
      <Footer/>
    </div>
  );
}
