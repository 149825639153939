import './GameCard.scss';
import { Ratio } from '../ratio/Ratio';
import { IGame } from '../../../platform/interfaces/game';
import { Link } from 'react-router-dom';
import { isMobile } from '../../../platform/utils/mobile';

export const GameCard = ({ game }: { game: IGame }) => {

  return (
    <Link to={`${isMobile() ? game.launchUrl : '/games/' + game.url}`} className="game-card mb-4" target={`${isMobile() ? '_blank' : '_self'}`}>
      {/*<h4 className="mt-2">{game.title}</h4>*/}
      {/*<p className="d-xs-none">{game.shortDescription}</p>*/}
      <Ratio ratio={60}>
        <div className="image-wrapper">
          <div className="image" style={{ 'backgroundImage': `url(${game.image})` }}></div>
        </div>
      </Ratio>
      <h4 className="mt-2 text-center">{game.title}</h4>
    </Link>
  );
}
