import './BlogDetailsPage.scss';
import { useParams } from 'react-router-dom';
import { blogStore } from '../../../platform/stores';
import { IBlogItem } from '../../../platform/interfaces/blog';
import { useEffect, useState } from 'react';

export const BlogDetailsPage = () => {
  const { blogId } = useParams();
  const [html, setHtml] = useState('');
  const [blog, setBlog] = useState<IBlogItem | null | undefined>(null);

  useEffect(() => {
    if (blogId) {
      let tempBlog = blogStore.getBlog(blogId);
      setBlog(tempBlog);
      if (tempBlog) {
        console.log(tempBlog)
        fetch(tempBlog.htmlFile)
          .then(res => res.text())
          .then(setHtml);
      }
    }
  }, []);

  return (
    <div className="blog-details-page">
      {blog && (
        <div className="container mt-2">
          <img src={blog.largeImage} alt=""/>
          <h1 className="mt-3">{blog.title}</h1>
          <div className="mt-3">
            <div dangerouslySetInnerHTML={{ __html: html }}></div>
          </div>
        </div>
      )}
    </div>
  );
}
