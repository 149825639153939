import { v4 as uuidv4 } from 'uuid';

class GuestStore {

  getId = () => {
    return uuidv4();
  }

}

export const guestStore = new GuestStore();
