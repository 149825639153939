import './Banner.scss';
import banner from '../../../assets/images/banner.png';

export const Banner = ({ title }: { title: string }) => {
  return (
    <div className="banner-component">
      <img src={banner} alt="Banner"/>
      <h1 className="display-1">{title}</h1>
    </div>
  );
}
