import './ContactUsPage.scss';
import { Banner } from '../../components/banner/Banner';
import { ContactUs } from '../../components/contact-us/ContactUs';

export const ContactUsPage = () => {
  return (
    <div className="contact-us-page">
      <Banner title="Contact us"/>
      <ContactUs/>
    </div>
  );
}
