import React from 'react';
import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MainLayout } from './modules/layouts/main-layout/MainLayout';
import { HomePage } from './modules/pages/home/HomePage';
import { GamesPage } from './modules/pages/games/GamesPage';
import { GameDetailsPage } from './modules/pages/game-details/GameDetailsPage';
import { ContactUsPage } from './modules/pages/contact-us/ContactUsPage';
import { ScrollToTop } from './modules/components/scroll-to-top/ScrollToTop';
import { BlogPage } from './modules/pages/blog/BlogPage';
import { BlogDetailsPage } from './modules/pages/blog-details/BlogDetailsPage';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<HomePage />} />
          <Route path="blog" element={<BlogPage />} />
          <Route path="games" element={<GamesPage />} />
          <Route path="contact-us" element={<ContactUsPage />} />
          <Route path="blog/:blogId" element={<BlogDetailsPage />} />
          <Route path="games/:gameId" element={<GameDetailsPage />} />
          <Route path="*" element={<HomePage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
