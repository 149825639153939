import './TopGames.scss';
import arrowLeft from '../../../../../assets/icons/arrow-left.svg';
import { Link } from 'react-router-dom';
import { GameCard } from '../../../../components/game-card/GameCard';
import { gameStore } from '../../../../../platform/stores';

export const TopGames = () => {
  const games = gameStore.getTopGames();

  return (
    <div className="top-games">
      <div className="container">
        <div className="row mb-5">
          <div className="col-sm-6">
            <h5 className="color-primary mb-3">ONLINE</h5>
            <h2 className="text-white">Top casino games</h2>
          </div>
        </div>
        <div className="row justify-content-between">
          {
            games.map((item) => (
              <div className="col-md-4 game-card-column mb-md-4 col-6" key={item.id}>
                <GameCard game={item}></GameCard>
              </div>
            ))
          }
        </div>
        <div className="d-flex justify-content-end">
          <Link to="/games" className="d-flex align-items-center view-all-btn">
            VIEW ALL
            <img src={arrowLeft} alt="Arrow Left" className="ms-3"/>
          </Link>
        </div>
      </div>
    </div>
  );
}
