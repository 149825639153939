import { IGame } from '../interfaces/game';
import { guestStore } from './guest-store';

class GameStore {

  private readonly _games: IGame[] = [
    {
      id: 14,
      url: 'alchemists-laboratory',
      rtp: '96, 94, 92, request',
      title: 'Alchemist\'s Laboratory',
      image: 'https://file-storage.fostcraft.com/uploads/alchemist-laboratory.png',
      launchUrl: `https://integrations.astriona.games/launcher/string/launch?gameId=14&currency=USD&operatorId=1&casinoId=1&token=222&languageCode=en&playerId=${guestStore.getId()}`,
      volatility: 'Very High',
      description: 'Enter a world of sweet delights with Caramel Crush, where cascading reels bring an extra layer of excitement to every spin. Experience the thrill of tumbling symbols and unlock the tantalizing bonus feature for a chance at even sweeter rewards. Get ready to satisfy your craving for big wins in this deliciously addictive slot game.',
      bonusFeature: true,
    },
    {
      id: 10,
      url: 'caramel-crush',
      rtp: '96, 94, 92, request',
      title: 'Caramel Crush',
      image: 'https://file-storage.fostcraft.com/uploads/caramel-crush.png',
      launchUrl: `https://integrations.astriona.games/launcher/string/launch?gameId=10&currency=USD&operatorId=1&casinoId=1&token=222&languageCode=en&playerId=${guestStore.getId()}`,
      volatility: 'Very High',
      description: 'Step into the mystical world of ancient alchemy where science and magic intertwine to unlock untold riches. Our latest creation transports players to the enchanting laboratory of a master alchemist, where bubbling potions and arcane symbols hold the promise of great fortune.',
      bonusFeature: true,
    },
    {
      id: 7,
      url: 'royal-gold-rush',
      rtp: '96, 94, 92, request',
      title: 'Royal Gold Rush',
      image: 'https://file-storage.fostcraft.com/uploads/royal-gold-rush.png',
      launchUrl: `https://integrations.astriona.games/launcher/string/launch?gameId=7&currency=USD&operatorId=1&casinoId=1&token=222&languageCode=en&playerId=${guestStore.getId()}`,
      volatility: 'High',
      description: 'Welcome to Royal Gold Rush, where riches await across 10 exhilarating pay lines. Join the quest for wealth and prestige in this high-stakes slot adventure. With dazzling graphics and exciting features, every spin promises excitement and potential riches fit for royalty. Explore the depths of fortune and indulge in the thrill of discovery with Royal Gold Rush.',
    },
    {
      id: 12,
      url: 'santas-reels',
      rtp: '96, 94, 92, request',
      title: 'Santa\'s Reels',
      image: 'https://file-storage.fostcraft.com/uploads/santas-reels.png',
      launchUrl: `https://integrations.astriona.games/launcher/string/launch?gameId=12&currency=USD&operatorId=1&casinoId=1&token=222&languageCode=en&playerId=${guestStore.getId()}`,
      volatility: 'High',
      description: 'Step into the festive spirit with Santa\'s Reels, a joyous 10-line slot game set in the heart of New Year\'s Eve celebrations. Spin through the holiday cheer as Santa brings gifts and excitement to every reel. With enchanting graphics and lively gameplay, Santa\'s Reels promises a magical gaming experience filled with joy and rewards.',
    },
    {
      id: 1,
      url: '7-hot-fruits',
      rtp: '96, 94, 92, request',
      title: '7 Hot Fruits',
      image: 'https://file-storage.fostcraft.com/uploads/7-hot-fruits.png',
      launchUrl: `https://integrations.astriona.games/launcher/string/launch?gameId=1&currency=USD&operatorId=1&casinoId=1&token=222&languageCode=en&playerId=${guestStore.getId()}`,
      volatility: 'High',
      description: 'Indulge in the classic allure of fruit machines with 7 Hot Fruits, a dynamic 10-line slot game bursting with nostalgic charm. Experience the timeless excitement as fiery fruits align for scorching wins in this exhilarating and vibrant gaming adventure.',
    },
    {
      id: 3,
      url: 'aztec-enigma',
      rtp: '96, 94, 92, request',
      title: 'Aztec Enigma',
      image: 'https://file-storage.fostcraft.com/uploads/aztect-enigma.png',
      launchUrl: `https://integrations.astriona.games/launcher/string/launch?gameId=3&currency=USD&operatorId=1&casinoId=1&token=222&languageCode=en&playerId=${guestStore.getId()}`,
      volatility: 'Medium',
      description: 'Dive into the mysteries of the Aztec civilization with Aztec Enigma, an enthralling slot game steeped in ancient lore. Uncover hidden treasures and unlock bonus features amidst the rich tapestry of Aztec culture. Embark on a thrilling journey through history and fortune.',
      bonusFeature: true,
    },
    {
      id: 9,
      url: 'keno-10',
      rtp: '88.5 - 99.6',
      title: 'Keno 10',
      image: 'https://file-storage.fostcraft.com/uploads/keno-10-banner.png',
      launchUrl: `https://integrations.astriona.games/launcher/string/launch?gameId=9&currency=USD&operatorId=1&casinoId=1&token=222&languageCode=en&playerId=${guestStore.getId()}`,
      description: 'Try your luck with our Keno games: Keno 8 and Keno 10! Pick your numbers and enjoy the suspense as the draw unfolds. With exciting gameplay and the chance to win big, these Keno variants promise an exhilarating gaming experience for all.'
    },
    {
      id: 8,
      url: 'keno-8',
      rtp: '88.5 - 99.6',
      title: 'Keno 8',
      image: 'https://file-storage.fostcraft.com/uploads/keno-8-banner.png',
      launchUrl: `https://integrations.astriona.games/launcher/string/launch?gameId=8&currency=USD&operatorId=1&casinoId=1&token=222&languageCode=en&playerId=${guestStore.getId()}`,
      description: 'Try your luck with our Keno games: Keno 8 and Keno 10! Pick your numbers and enjoy the suspense as the draw unfolds. With exciting gameplay and the chance to win big, these Keno variants promise an exhilarating gaming experience for all.'
    },
    {
      id: 2,
      url: 'neo-vegas',
      rtp: '96, 94, 92, request',
      title: 'Neo Vegas',
      image: 'https://file-storage.fostcraft.com/uploads/neo-vegas.png',
      launchUrl: `https://integrations.astriona.games/launcher/string/launch?gameId=2&currency=USD&operatorId=1&casinoId=1&token=222&languageCode=en&playerId=${guestStore.getId()}`,
      volatility: 'High',
      description: 'Experience the electrifying allure of the city that never sleeps with Neo Vegas, a dazzling slot game pulsating with neon lights and urban excitement. Immerse yourself in the vibrant energy of the cityscape as you spin the reels for electrifying wins and non-stop thrills.'
    },
    {
      id: 6,
      url: 'samurai-spin',
      rtp: '96, 94, 92, request',
      title: 'Samurai Spin',
      image: 'https://file-storage.fostcraft.com/uploads/samurai-spin.png',
      launchUrl: `https://integrations.astriona.games/launcher/string/launch?gameId=6&currency=USD&operatorId=1&casinoId=1&token=222&languageCode=en&playerId=${guestStore.getId()}`,
      volatility: 'High',
      description: 'Enter the realm of ancient Japan with Samurai Spin, a mesmerizing slot game blending traditional aesthetics with modern excitement. Journey through the land of the rising sun and unlock thrilling bonus features amidst the beauty of Japanese culture. Prepare for an epic adventure unlike any other.',
      bonusFeature: true,
    },
    {
      id: 5,
      url: '7-hot-fruits-5-line',
      rtp: '96, 94, 92, request',
      title: '7 Hot Fruits - 5 Lines',
      image: 'https://file-storage.fostcraft.com/uploads/7-hot-fruits-5-line.png',
      launchUrl: `https://integrations.astriona.games/launcher/string/launch?gameId=5&currency=USD&operatorId=1&casinoId=1&token=222&languageCode=en&playerId=${guestStore.getId()}`,
      volatility: 'High',
      description: 'Indulge in the classic allure of fruit machines with 7 Hot Fruits, a dynamic 5-line slot game bursting with nostalgic charm. Experience the timeless excitement as fiery fruits align for scorching wins in this exhilarating and vibrant gaming adventure.',
    },
    {
      id: 11,
      url: 'plinko',
      rtp: '96',
      title: 'Plinko',
      image: 'https://file-storage.fostcraft.com/uploads/plinko-banner.png',
      launchUrl: `https://integrations.astriona.games/launcher/string/launch?gameId=11&currency=USD&operatorId=1&casinoId=1&token=222&languageCode=en&playerId=${guestStore.getId()}`,
      // volatility: 'Low',
      description: 'Plinko is a classic probability game where the player drops a ball into a pyramid-shaped grid filled with pins. As the ball travels down, it bounces unpredictably between the pins before settling in a slot at the bottom. The payout depends on where the ball lands, with higher rewards typically near the edges of the pyramid and lower rewards in the center.',
    },
    {
      id: 15,
      url: 'limbo',
      rtp: '99',
      title: 'Limbo',
      image: 'https://file-storage.fostcraft.com/uploads/limbo-banner.png',
      launchUrl: `https://integrations.astriona.games/launcher/string/launch?gameId=15&currency=USD&operatorId=1&casinoId=1&token=222&languageCode=en&playerId=${guestStore.getId()}`,
      // volatility: 'Medium',
      description: 'Limbo is a simple crypto game. Players select a bet and a target multiplier, and the outcome is determined by whether the selected value is higher or lower than the one generated in the game. Despite its simplicity, Limbo offers interesting features that allow players to develop their own strategies, making it an enjoyable and captivating experience.',
    },
    // {
    //   id: 17,
    //   url: 'wheel',
    //   rtp: '99',
    //   title: 'Wheel',
    //   image: 'https://file-storage.fostcraft.com/uploads/wheel-banner.png',
    //   launchUrl: `https://integrations.astriona.games/launcher/string/launch?gameId=17&currency=USD&operatorId=1&casinoId=1&token=222&languageCode=en&playerId=${guestStore.getId()}`,
    //   //volatility: 'Medium',
    //   description: 'Indulge in the classic allure of fruit machines with 7 Hot Fruits, a dynamic 5-line slot game bursting with nostalgic charm. Experience the timeless excitement as fiery fruits align for scorching wins in this exhilarating and vibrant gaming adventure.',
    // },
  ];

  getGames = (url: string | null = null): IGame[] => {
    if (url)
      return this._games.filter(x => x.url != url);
    return this._games;
  }

  getGame = (url: string): IGame | undefined => {
    return this._games.find(x => x.url === url);
  }

  getTopGames = (): IGame[] => {
    return [...this._games].slice(0, 6);
  }

}

export const gameStore = new GameStore();
