import './AboutUs.scss';
import image from '../../../../../assets/images/home-about-us.png';

export const AboutUs = () => {
  return (
    <div className="home-about-us">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <img src={image} alt="Cards"/>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <h5 className="color-primary mb-3">CREATIVE APPROACH</h5>
            <h2 className="mb-3 text-white">We develop & create <br/> online games</h2>
            <p className="text-white">
              We are dedicated to crafting cutting-edge slot games that redefine entertainment. With a keen eye for innovation and a passion for
              player engagement, we specialize in creating captivating experiences that keep players coming back for more. Our games combine stunning
              visuals, engaging gameplay mechanics, and seamless user experiences, all designed to deliver a thrill like no other. Discover the future
              of slot gaming with us.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
