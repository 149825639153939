import './BlogCard.scss';
import { Ratio } from '../../../../components/ratio/Ratio';
import { IBlogItem } from '../../../../../platform/interfaces/blog';
import { Link } from 'react-router-dom';

export const BlogCard = ({item}: { item: IBlogItem }) => {
  return (
    <Link to={'/blog/' + item.url} className="blog-card">
      <Ratio ratio={50}>
        <div className="image" style={{'backgroundImage': `url(${item.smallImage})`}}></div>
      </Ratio>
      <h4 className="mt-3">{item.title}</h4>
      <div className="btn custom-btn btn-lg mt-3">READ MORE</div>
    </Link>
  );
}
