import './BlogPage.scss';
import { Banner } from '../../components/banner/Banner';
import { BlogCard } from './components/blog-card/BlogCard';
import { blogStore } from '../../../platform/stores';

export const BlogPage = () => {
  const blogItems = blogStore.getBlogs();

  return (
    <div className="blog-page">
      <Banner title="Blog"/>
      <div className="container mt-5">
        <div className="row">
          {
            blogItems.map((item) => (
              <div className="col-md-6 mb-5" key={item.id}>
                <BlogCard item={item}></BlogCard>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}
