import './GameDetailsPage.scss';
import { useParams } from 'react-router-dom';
import { gameStore } from '../../../platform/stores';
import { IGame } from '../../../platform/interfaces/game';
import React, { useRef } from 'react';
import { Ratio } from '../../components/ratio/Ratio';
import fullScreen from '../../../assets/icons/full-screen.svg';
import { GameCard } from '../../components/game-card/GameCard';

export const GameDetailsPage = () => {
  const gameFrame = useRef<HTMLIFrameElement>(null);
  const { gameId } = useParams();
  const games = gameStore.getGames(gameId);
  let game: IGame | undefined;
  if (gameId) {
    game = gameStore.getGame(gameId);
  }

  function openFullscreen() {
    if (gameFrame) {
      const elem = gameFrame.current;
      if (elem) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen().then();
        }
      }
    }
  }

  return (
    <div className="game-details-page">
      <div className="container">
        {
          game
            ?
            <>
              <div className="game-frame">
                <Ratio ratio={56.25}>
                  <iframe src={game.launchUrl} title={game.title} ref={gameFrame}></iframe>
                </Ratio>
                <div className="game-bar d-flex justify-content-end">
                  <img src={fullScreen} alt="Full screen icon" onClick={() => openFullscreen()}/>
                </div>
              </div>
              <h1 className="mt-3">{game.title}</h1>
              <p className="mt-3">{game.description}</p>
              <div className="mt-3">
                <table>
                  <tbody>
                  <tr>
                    <td>RTP</td>
                    <td>{game.rtp}</td>
                  </tr>
                  {game.volatility && (
                    <tr>
                      <td>Volatility</td>
                      <td>{game.volatility}</td>
                    </tr>
                  )}
                  {game.bonusFeature && (
                    <tr>
                      <td>Bonus feature</td>
                      <td>Yes</td>
                    </tr>
                  )}
                  </tbody>
                </table>
              </div>
            </>
            :
            <></>
        }
        <div className="section">
          <h5 className="color-primary mb-3">ONLINE</h5>
          <h2 className="text-white">Our games</h2>
          <div className="row mt-5 justify-content-between">
            {
              games.map((item) => (
                <div className="col-md-4 game-card-column mb-md-4 col-6" key={item.id}>
                  <GameCard game={item}></GameCard>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}
