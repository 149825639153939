import { IBlogItem } from '../interfaces/blog';

class BlogStore {

  private blogs: IBlogItem[] = [
    {
      id: 1,
      url: 'spin-to-learn',
      title: 'Spin to Learn: Unveiling the Educational Potential of Slot Games in Math and Probability',
      smallImage: '/assets/images/spin-to-learn.webp',
      largeImage: '/assets/images/spin-to-learn.webp',
      htmlFile: '/assets/texts/blog/spin-to-learn.html'
    },
    {
      id: 2,
      url: 'online-casino-vs-land-based-casinos',
      title: 'Online Casino vs. Land-Based Casinos: Navigating the Gambling Landscape',
      smallImage: '/assets/images/online-vs-land-based.webp',
      largeImage: '/assets/images/online-vs-land-based.webp',
      htmlFile: '/assets/texts/blog/online-casino-vs-land-based-casinos.html'
    },
    {
      id: 3,
      url: 'slot-machine-history',
      title: 'Unveiling the Rich Tapestry of Slot Machine History: From Humble Beginnings to Digital Dominance',
      smallImage: '/assets/images/slot-history.webp',
      largeImage: '/assets/images/slot-history.webp',
      htmlFile: '/assets/texts/blog/slot-machine-history.html'
    },
    {
      id: 4,
      url: 'responsible-gaming',
      title: 'Responsible Gaming: A Commitment to Player Well-Being',
      smallImage: '/assets/images/responsible-gaming.webp',
      largeImage: '/assets/images/responsible-gaming.webp',
      htmlFile: '/assets/texts/blog/responsible-gaming.html'
    },
  ];

  getBlogs(): IBlogItem[] {
    return this.blogs;
  }

  getBlog(url: string): IBlogItem | undefined {
    return this.blogs.find(x => x.url == url);
  }

}

export const blogStore = new BlogStore();
