import './HomePage.scss';
import { HomeBanner } from './components/home-banner/HomeBanner';
import { AboutUs } from './components/about-us/AboutUs';
import { TopFeatures } from './components/top-features/TopFeatures';
import { TopGames } from './components/top-games/TopGames';
import { ContactUs } from '../../components/contact-us/ContactUs';

export const HomePage = () => {
  return (
    <div className="home-page">
      <HomeBanner />
      <div className="section">
        <AboutUs />
      </div>
      <div className="section">
        <TopFeatures />
      </div>
      <div className="section">
        <TopGames />
      </div>
      <div className="section">
        <ContactUs/>
      </div>
    </div>
  );
}
